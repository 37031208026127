import React, { Fragment } from "react"
import { compose } from "recompose"
import Layout from "../components/layout"
import { AuthUserContext, withAuthorization } from "../components/Session"
import { CountryDropdown, RegionDropdown } from "react-country-region-selector"
import { navigate } from "gatsby"
import LoadingOverlay from "react-loading-overlay"
import CheckoutPricing from "../components/CheckoutPricing"
// import { withFirebase, FirebaseContext } from "../components/Firebase"

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props)
    let locationState = props.location.location.state
    // const search = typeof window !== "undefined" ? window.location.search : ""
    // const query = queryString.parse(search)
    let product = ""
    let numUsers = 2
    if (locationState !== null) {
      if ("product" in locationState) {
        product = locationState.product
      }
      if ("numUsers" in locationState) {
        numUsers = locationState.numUsers
      }
    }

    this.state = {
      country: "",
      region: "",
      numUsers: numUsers,
      product: product,
      loading: false,
      showWindowPortal: false,
      counter: 0,
    }
    this.toggleWindowPortal = this.toggleWindowPortal.bind(this)
    // console.log("props", props)
    // console.log("this:", this)
    // console.log(this.state)
  }

  componentDidMount() {
    window.setInterval(() => {
      this.setState(state => ({
        ...state,
        counter: state.counter + 1,
      }))
    }, 1000)
  }

  toggleWindowPortal() {
    this.setState(state => ({
      ...state,
      showWindowPortal: !state.showWindowPortal,
    }))
  }

  handleSubmit = id => async event => {
    event.preventDefault()

    if (!document.getElementById("agree").checked) {
      alert(
        "Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy."
      )
      return false
    }
    // console.log("handleSubmit")

    const customerInfo = {
      product: event.target.product.value,
      numUsers: event.target.numUsers.value,
      firstName: event.target.firstName.value,
      lastName: event.target.lastName.value,
      email: event.target.email.value,
      id: id,
      phone: event.target.phone.value,
      company: event.target.company.value,
      address: {
        city: event.target.city.value,
        country: this.state.country,
        line1: event.target.address1.value,
        line2: event.target.address2.value,
        state: this.state.region,
        postalCode: event.target.postalCode.value,
      },
    }

    // console.log(customerInfo)
    navigate("/checkout-page-two/", {
      state: { customerInfo },
    })
  }

  selectCountry(val) {
    this.setState({ country: val })
  }

  selectRegion(val) {
    this.setState({ region: val })
  }

  setNumUsers(event) {
    const users = event.target.value
    // console.log("num users changed to ", users)
    this.setState({ numUsers: users })
    // console.log("num users:", this.state)
  }

  setProduct(event) {
    const product = event.target.value
    // console.log("product changed to", product)
    this.setState({ product: product })
  }

  render() {
    const { country, region, product, numUsers } = this.state
    // console.log("product", product)
    // console.log("numUsers", numUsers)

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Fragment>
            <MyLoader active={this.state.loading}>
              <form onSubmit={this.handleSubmit(authUser.uid)}>
                <div className="row">
                  <div className="col-md-8 col-lg-8 card mb-3">
                    <h5 className="card-title mt-3">Software Information</h5>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label htmlFor={"product"}>Product:</label>
                          <select
                            id="product"
                            name="product"
                            defaultValue={product}
                            className="form-control"
                            onChange={e => this.setProduct(e)}
                            required
                          >
                            <option value="" disabled hidden>
                              Select Product
                            </option>
                            <option value="hass">HASS</option>
                            <option value="hassHouse">HASS House</option>
                            <option value="coosa">COOSA</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label htmlFor={"numUsers"}>Number of users:</label>
                          <select
                            id="numUsers"
                            name="numUsers"
                            defaultValue={numUsers}
                            className="form-control"
                            onChange={e => this.setNumUsers(e)}
                            required
                          >
                            <option value="" disabled hidden>
                              Number of Users
                            </option>
                            <option value="2">1-2 (base price)</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 mb-3">
                    <div className="col-md-12 col-lg-12 card">
                      <h5 className="card-title mt-3">Price Info</h5>
                      <div className="mb-3 text-center">
                        <CheckoutPricing
                          product={product}
                          numUsers={numUsers}
                          user={authUser}
                          state={region}
                        />
                      </div>
                    </div>
                  </div>
                  <CustomerInformation
                    product={product}
                    user={authUser}
                    country={country}
                    region={region}
                    that={this}
                  />
                </div>
                {!alreadyOwnsSoftware(product, authUser) && (
                  <div className="row">
                    <div className="col-lg-12 col-md-12 text-center">
                      <button
                        type="submit"
                        className="default-btn"
                        disabled={this.state.loading}
                      >
                        <i className="bx bxs-arrow-to-right align-left"></i>
                        Continue to Payment
                        <span></span>
                      </button>
                    </div>
                  </div>
                )}
              </form>
            </MyLoader>
          </Fragment>
        )}
      </AuthUserContext.Consumer>
    )
  }
}

function alreadyOwnsSoftware(product, user) {
  if (product === "hass" && "licenseID" in user) {
    return true
  } else if (product === "hassHouse" && "hassHouseLicenseID" in user) {
    return true
  } else if (product === "coosa" && "coosaLicenseID" in user) {
    return true
  }
  return false
}

function CustomerInformation(props) {
  let authUser = props.user
  let country = props.country
  let region = props.region
  let that = props.that

  if (!alreadyOwnsSoftware(props.product, props.user)) {
    return (
      <div className="col-md-8 col-lg-8 card">
        <h5 className="card-title mt-3">Customer Information</h5>

        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="firstName"
                id="firstName"
                className="form-control"
                placeholder="First Name"
                defaultValue={authUser["firstName"]}
                required
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="lastName"
                id="lastName"
                className="form-control"
                placeholder="Last Name"
                defaultValue={authUser["lastName"]}
                required
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="form-group">
              <input
                type="text"
                name="company"
                id="company"
                className="form-control"
                placeholder="Company"
                defaultValue={authUser["company"]}
                required
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="form-group">
              <input
                type="email"
                name="email"
                id="email"
                className="form-control"
                placeholder="Email"
                defaultValue={authUser["email"]}
                required
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="form-group">
              <input
                type="tel"
                name="phone"
                id="phone"
                className="form-control"
                placeholder="Phone Number"
                defaultValue={authUser["phone"]}
                required
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="form-group">
              <input
                type="text"
                name="address1"
                id="address1"
                className="form-control"
                placeholder="Address Line 1"
                required
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="form-group">
              <input
                type="text"
                name="address2"
                id="address2"
                className="form-control"
                placeholder="Address Line 2"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="form-group">
              <input
                type="text"
                name="city"
                id="city"
                className="form-control"
                placeholder="City"
                required
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="form-group">
              <CountryDropdown
                name="country"
                id="country"
                className="form-control"
                value={country}
                onChange={val => that.selectCountry(val)}
                valueType={"short"}
                required
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="form-group">
              <RegionDropdown
                country={country}
                value={region}
                onChange={val => that.selectRegion(val)}
                name="state"
                id="state"
                className="form-control"
                countryValueType="short"
                required
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="form-group">
              <input
                type="text"
                name="postalCode"
                id="postalCode"
                className="form-control"
                placeholder="Postal Code"
                required
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="form-group text-center">
              <input
                required
                type="checkbox"
                name="checkbox"
                value="check"
                id="agree"
                className="form-check-input"
                checked
              />{" "}
              I have read and agree to the{" "}
              <a
                href="/terms-and-conditions"
                target="_blank"
              // onClick="window.open('/terms-and-conditions','name','width=600,height=400')"
              >
                Terms and Conditions
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="col-md-8 col-lg-8 card">
        <h5 className="card-title mt-3">Customer Information</h5>

        <div className="row">
          <div className="col-lg-12 col-md-12">
            <p>It looks like you already have a HASS license.</p>
            <p>
              If you are trying to renew, please navigate to the account page
              and click the "Renew License" button.
            </p>
            <p>
              If you need to add a user, please email us at hass@hrssystems.com
            </p>
          </div>
        </div>
      </div>
    )
  }
}

function MyLoader({ active, children }) {
  return (
    <LoadingOverlay
      active={active}
      spinner
      styles={{
        overlay: base => ({
          ...base,
          background: "rgba(255, 255, 255, 0.5)",
        }),
        spinner: base => ({
          ...base,
          width: "100px",
          "& svg circle": {
            stroke: "rgba(199, 59, 27, 1)",
          },
        }),
      }}
    >
      {children}
    </LoadingOverlay>
  )
}

const condition = authUser => !!authUser

const ProtectedCheckoutForm = compose(withAuthorization(condition, "checkout"))(
  CheckoutForm
)

const CheckoutPage = location => {
  return (
    <Layout>
      <section className="free-trial-area pt-70 pb-100 bg-gray-50">
        <div className="container">
          <div className="order-form">
            <h1>Order Form</h1>
            <ProtectedCheckoutForm location={location} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CheckoutPage
